<template>
  <div class="web">
    <!-- <img src="https://cdn.mekoomall.com/static/pc/manager/墨库商城平台对用户交易纠纷处理的机制或方案.jpg" alt=""> -->
    <div v-html="richText"></div>
  </div>
</template>
<script>
import {getPrivacyPolicy} from '@/api/certificates'
export default {
  data() {
    return {
      richText: ""
    }
  },
  mounted() {
    this.getPrivacyPolicy();
  },
  methods: {
    async getPrivacyPolicy() {
      let res = await getPrivacyPolicy();
      if(res) {
        this.richText = res
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.web {
  width: 1200px;
  margin: 0 auto;
  padding: 30px 0;
  display: flex;
  justify-content: center;
  img {
    max-width: 100%;
  }
}
</style>